@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #034844;
  --highlight-color:#4caa3c;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: #f4f4f4;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: #034844;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #034844;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: #034844;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  font-family: Poppins;
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
input[type="date" i] {
    align-items: center;
    font-family: Poppins, sans-serif;
    -webkit-padding-start: 1px;
            padding-inline-start: 1px;
    cursor:text;
    overflow: hidden;
    padding: 6px;
}

input:focus-visible, textarea:focus-visible {
  outline: #4caa3c 1px solid;
}


.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
.App {
  display: flex;
}
.App .container{
  flex-grow: 1;
  padding: 0 60px;
}
.avatar {
  -webkit-filter: invert(56%) sepia(21%) saturate(1308%) hue-rotate(65deg) brightness(96%) contrast(92%);
          filter: invert(56%) sepia(21%) saturate(1308%) hue-rotate(65deg) brightness(96%) contrast(92%);
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
}
.project-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.project-list a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.project-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-list ul {
  margin: 10px 0;
  display: flex;
}
.project-list li {
  margin-right: 10px;
}
.project-list .avatar {
  width: 30px;
  height: 30px;
}
.project-filter {
 width: -webkit-fit-content;
 width: -moz-fit-content;
 width: fit-content;
  margin: 30px 0;
}
.project-filter nav {
  
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
}
.project-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}
.project-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.project-filter button:last-child {
  border: 0;
}
.project-filter button.active {
  color: var(--primary-color);
} 
.create-form {
  max-width: 600px;
}
.forgot-pwd {
    margin-top: 8px;
    font-size: 0.8rem;
}
.login-form {
  max-width: 320px;
  margin: 30px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}

.form-container {
  display: flex;
  grid-gap:90px;
  gap:90px;
}

.form-column {
  width:50%;
}
.project-details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  grid-gap: 60px;
}

/* project summary */
.project-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
}
.project-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.project-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.project-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.project-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

/* project comments */
.comments-section{
    max-height:55vh;
    overflow-y: auto;
}
.project-comments label {
  margin-bottom: 0px;
}
.project-comments textarea {
  min-height: 40px;
  font-size: 1.5em;
}

/* comment list */
.project-comments h4 {
  color: var(--heading-color);
  margin-bottom: 10px;
}
.project-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-dates {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
}
.navbar {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--heading-color);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.navbar .logo img {
  margin-right: 10px;
  /* filter: invert(25%); */
  width: 75px;
  margin-top: -8px;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .user {
  font-weight: normal;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.sidebar .links {
  margin-top: 50px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.sidebar .links a.active {
  color: #fff;
  background: var(--highlight-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  color: #fff;
}
.user-list {
  width: 150px;
  min-width: 200px;
  padding: 30px;
  box-sizing: border-box;
  background: #fbfbfb;
  color: var(--heading-color);
}
.user-list h2 {
  text-align: right;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 1.2em;
}
.user-list .user-list-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px auto;
  font-size: smaller;
}
.user-list .avatar {
  margin-left: 10px;
  width: 40px;
  height: 40px;
}
/* online users */
.user-list .online-user {
  display: inline-block;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  background: #0ebb50;
  border-radius: 50%;
  margin-top: -2px;
}

/* offline users */
.user-list .offline-user {
  display: inline-block;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  background: #ee0a0a;
  border-radius: 50%;
  margin-top: -2px;
}

.add-figures-form {
  max-width: 100%;
  
}
.rx-container {
    margin-top: 30px;
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr;
    gap:30px; */
}
.rx-container span{
    font-size: 13px;
}

.add-figures-form h2{
    margin-bottom: 2rem;
}
fieldset {
    border-width: 2px;
    border-style:solid;
}
.input-container {
    grid-gap:20px;
    gap:20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

legend {
    
    color: #3e3e3e;
    padding: 3px 30px;
    border-radius: 5px;
    width:130px;
    font-size: 1.3rem;
}
.fp10paper {
    background-color: #C3E0A4
}

.fp10mda {
    background-color: rgb(64, 180, 196);
}

.token {
    background-color: rgb(255, 255, 255);
    color: rgb(109, 105, 105);
}

.add-figures-form fieldset{
    border-radius: 5px;
    margin-bottom: 30px;
}  

#dateInput {
    width:200px;
    height:40px;
    border-radius: 5px;
}

.submit-figures-btn {
    margin-left: 3px;
}

.date-label {
    padding-top: 8px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin-left: 10px;
    margin-right: 15px;
   
}

.date-div, .branch-div {
    display: flex;
    align-items: center;
}

.btn-container {
    display: flex;
    justify-content: space-between;
}

.td-noData {
    width:100%;
    height:200px;
}
.rx-figures-table table {
    width:100%;
    text-align:center;
    margin-top:20px;
}

.rxFigures-th {
    font-size: 0.9rem;
}

.th-date {
    width:10%;
}

th {
    padding-bottom: 20px;
}

td {
    font-size: 0.9em;
    padding-bottom: 10px;
}
.start-date{
    width:200px;
}

.month-filter .btn {
    margin-right: 2px;
    transition: 200ms;
}

.month-filter .btn:hover {
    background-color:var(--highlight-color);
    color:#fff;
}

/* .month-filter .btn:active {
    background-color:#034844;
    color:#fff;
} */

.active {
    background-color: var(--highlight-color);
    color: #fff;
}

.no-data-msg {
    position: relative;
    top: 100px;
    left: 43%;
}

.nav-div {
    width:13rem;
    margin: 35px 0;
}
