.rx-figures-table table {
    width:100%;
    text-align:center;
    margin-top:20px;
}

.rxFigures-th {
    font-size: 0.9rem;
}

.th-date {
    width:10%;
}

th {
    padding-bottom: 20px;
}

td {
    font-size: 0.9em;
    padding-bottom: 10px;
}
.start-date{
    width:200px;
}

.month-filter .btn {
    margin-right: 2px;
    transition: 200ms;
}

.month-filter .btn:hover {
    background-color:var(--highlight-color);
    color:#fff;
}

/* .month-filter .btn:active {
    background-color:#034844;
    color:#fff;
} */

.active {
    background-color: var(--highlight-color);
    color: #fff;
}

.no-data-msg {
    position: relative;
    top: 100px;
    left: 43%;
}

.nav-div {
    width:13rem;
    margin: 35px 0;
}