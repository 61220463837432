.login-form {
  max-width: 320px;
  margin: 30px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}

.form-container {
  display: flex;
  gap:90px;
}

.form-column {
  width:50%;
}