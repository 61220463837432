.add-figures-form {
  max-width: 100%;
  
}
.rx-container {
    margin-top: 30px;
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr;
    gap:30px; */
}
.rx-container span{
    font-size: 13px;
}

.add-figures-form h2{
    margin-bottom: 2rem;
}
fieldset {
    border-width: 2px;
    border-style:solid;
}
.input-container {
    gap:20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

legend {
    
    color: #3e3e3e;
    padding: 3px 30px;
    border-radius: 5px;
    width:130px;
    font-size: 1.3rem;
}
.fp10paper {
    background-color: #C3E0A4
}

.fp10mda {
    background-color: rgb(64, 180, 196);
}

.token {
    background-color: rgb(255, 255, 255);
    color: rgb(109, 105, 105);
}

.add-figures-form fieldset{
    border-radius: 5px;
    margin-bottom: 30px;
}  

#dateInput {
    width:200px;
    height:40px;
    border-radius: 5px;
}

.submit-figures-btn {
    margin-left: 3px;
}

.date-label {
    padding-top: 8px;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 10px;
    margin-right: 15px;
   
}

.date-div, .branch-div {
    display: flex;
    align-items: center;
}

.btn-container {
    display: flex;
    justify-content: space-between;
}